import React from "react";
import { HeaderAction } from "../components/HeaderAction";
import { Box, Button, Title } from "@mantine/core";
import { useApi } from "../useApi";
import { getTasks, getUsersCustomers, getUsersMembers } from "../apiRoutes";
import { TaskDto, TaskState, TaskType, UserDto } from "../dto/user";
import TableInit from "../components/table/TableInit";
import { ColumnDef, Row, sortingFns } from "@tanstack/react-table";
import { useDisclosure } from "@mantine/hooks";
import moment from "moment";
import { IconCalendarPlus } from "@tabler/icons-react";
import { formatDateToUTC } from "../utils";
import TaskForm from "../components/TaskForm";

const getCustomerDisplayText = (customer: any) => {
  const code = customer?.code || "";
  const processingLevel = customer?.processingLevel || "";
  const comment = customer?.comment || "";

  const extras =
    processingLevel || comment
      ? `(${[processingLevel, comment].filter(Boolean).join(" ")})`
      : "";

  return `${code} ${extras}`.trim();
};

const formatDate = (date: any) => moment(date).format("DD/MM/YYYY");

const columnsRecordings: ColumnDef<TaskDto>[] = [
  {
    accessorKey: "id",
    header: "Début/Fin",
    cell: ({ row }) => (
      <span>
        {row.original?.startDate
          ? moment(row.original.startDate)
              .subtract(2, "hours")
              .format("DD/MMàHH:mm")
          : ""}{" "}
        {row.original?.endDate
          ? moment(row.original.endDate)
              .subtract(2, "hours")
              .format("DD/MMàHH:mm")
          : ""}
      </span>
    ),
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    accessorKey: "date",
    id: "date",
    header: "Date",
    accessorFn: (row) => formatDate(row.date),
    cell: ({ row }) => <>{formatDate(row.original.date)}</>,
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    id: "customer",
    header: "Client",
    accessorFn: (row) => getCustomerDisplayText(row.customer),
    cell: ({ row }) => <>{getCustomerDisplayText(row.original.customer)}</>,
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    accessorKey: "redactor.code",
    header: "Code Rédacteur",
    cell: (info) => info.getValue(),
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    accessorKey: "meetingDate",
    header: "Date réunion",
    cell: (info) => info.getValue(),
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    accessorKey: "duration",
    header: "Durée",
    cell: (info) => info.getValue(),
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    accessorKey: "comment",
    header: "Comment. Admin / Redac",
    cell: ({ row }) => (
      <>
        {row.original.comment} / {row.original.commentRedaction}
      </>
    ),
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    accessorKey: "endDate",
    header: " ",
    cell: ({ row }) => <PlanTask row={row} />,
    enableColumnFilter: false,
    enableSorting: false,
  },
];

const columnsMeetings: ColumnDef<TaskDto>[] = [
  {
    accessorKey: "id",
    header: "Début/Fin",
    cell: ({ row }) => (
      <span>
        {row.original?.startDate
          ? moment(row.original.startDate)
              .subtract(2, "hours")
              .format("DD/MMàHH:mm")
          : ""}{" "}
        {row.original?.endDate
          ? moment(row.original.endDate)
              .subtract(2, "hours")
              .format("DD/MMàHH:mm")
          : ""}
      </span>
    ),
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    id: "startDate",
    header: "Date",
    accessorFn: (row) => formatDate(row.startDate),
    cell: ({ row }) => (
      <>{row.original.startDate ? formatDate(row.original.startDate) : null}</>
    ),
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    id: "customer",
    header: "Client",
    accessorFn: (row) => getCustomerDisplayText(row.customer),
    cell: ({ row }) => <>{getCustomerDisplayText(row.original.customer)}</>,
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    accessorKey: "redactor.code",
    header: "Code Rédacteur",
    cell: (info) => info.getValue(),
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    accessorKey: "meetingDate",
    header: "Date réunion",
    cell: (info) => info.getValue(),
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    accessorKey: "comment",
    header: "Com. Admin / Redac / Réu",
    cell: ({ row }) => (
      <>
        {row.original.comment} / {row.original.commentRedaction} /{" "}
        {row.original.commentRedactor}
      </>
    ),
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    accessorKey: "place",
    header: "Lieu",
    cell: (info) => info.getValue(),
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    accessorKey: "endDate",
    header: " ",
    cell: ({ row }) => <PlanTask row={row} />,
    enableColumnFilter: false,
    enableSorting: false,
  },
];

const PlanTask = ({ row }: { row: Row<TaskDto> }) => {
  const buttonType =
    row.original.state !== TaskState.billed &&
    row.original.state !== TaskState.delivered &&
    row.original.startDate &&
    row.original.endDate
      ? "Livrer"
      : row.original.state === TaskState.delivered
      ? "Facturer"
      : row.original.state === TaskState.billed
      ? "Modifier"
      : "Planifier";
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "right",
      }}
      onClick={(e) => {
        e.stopPropagation();
        console.log(111, row);
        const deleteEvent = new CustomEvent("planTask", {
          detail: { taskId: row.original.id, buttonType },
        });
        window.dispatchEvent(deleteEvent);
      }}
    >
      <Button
        leftIcon={
          !row.original.startDate &&
          !row.original.endDate && <IconCalendarPlus />
        }
      >
        {buttonType}
      </Button>
    </Box>
  );
};

const Tasks = () => {
  const isRecordings = window.location.pathname.includes("/enregistrements");
  const [recordings, setRecordings] = React.useState<TaskDto[]>([]);
  const [meetings, setMeetings] = React.useState<TaskDto[]>([]);
  const [tasks, setTasks] = React.useState<TaskDto[]>([]);
  const [users, setUsers] = React.useState<
    {
      label: string;
      value: string;
      places: string;
      processinglevel: string;
      averagmeetingduration: number;
    }[]
  >([]);
  const [redactors, setRedactors] = React.useState<
    { label: string; value: string }[]
  >([]);
  const [loading, setLoading] = React.useState(true);
  const [opened, { open, close }] = useDisclosure(false);
  const api = useApi();
  const [refreshCounter, setRefreshCounter] = React.useState(0);
  const [isPlanning, setIsPlanning] = React.useState(false);
  const [resetFormCounter, setResetFormCounter] = React.useState(0);

  React.useEffect(() => {
    const fetchTasks = async () => {
      const { data }: { data: TaskDto[] } = await getTasks(api);
      console.log("tasks", data);
      console.log("task [20]", data[20]);
      setRecordings(
        data.filter((task) => task.type === TaskType.recordingWriting)
      );
      setMeetings(data.filter((task) => task.type === TaskType.meeting));
      setLoading(false);
      setTasks(data);
    };
    const fetchUsersMembers = async () => {
      const { data }: { data: UserDto[] } = await getUsersMembers(api);
      console.log("members", data);
      const users = data.map((user) => ({
        label: `${user.code} (${formatDateToUTC(user.nextAvailability)})`,
        value: JSON.stringify(user.id) ?? "",
      }));
      setRedactors(users);
      setLoading(false);
    };
    const fetchUsersCustomers = async () => {
      const { data }: { data: UserDto[] } = await getUsersCustomers(api);
      const users = data.map((user) => ({
        label: `${user.code} (${user.email}) ${
          user.processingLevel ? "(" + user.processingLevel + ")" : ""
        }`,
        value: user.id?.toString() ?? "",
        places: user.places ?? "",
        processinglevel: user.processingLevel ?? "",
        averagmeetingduration: user.averageMeetingDuration ?? 0,
      }));
      setUsers(users);
    };
    fetchTasks();
    fetchUsersMembers();
    fetchUsersCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, refreshCounter]);

  return (
    <Box>
      <HeaderAction planning />
      <TaskForm
        opened={opened}
        open={open}
        close={close}
        isPlanning={isPlanning}
        isRecordings={isRecordings}
        api={api}
        setRefreshCounter={setRefreshCounter}
        users={users}
        redactors={redactors}
        setIsPlanning={setIsPlanning}
        tasks={tasks}
        resetFormCounter={resetFormCounter}
      />
      <Box p={30} bg="#F9FAFB" mih={"calc(100vh - 76px)"}>
        <Title mb={30}>
          {isRecordings ? "Enregistrements" : "Réunions en présentiel"}
        </Title>
        <TableInit
          data={isRecordings ? recordings : meetings}
          setData={isRecordings ? setRecordings : setMeetings}
          loading={loading}
          columns={isRecordings ? columnsRecordings : columnsMeetings}
          open={() => {
            open();
            setIsPlanning(false);
            setResetFormCounter(resetFormCounter + 1);
          }}
          isTasks
          redirect="isTasks"
        />
      </Box>
    </Box>
  );
};

export default Tasks;
