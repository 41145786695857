import { Moment } from "moment";
import { TaskDto, TaskState, TaskType } from "../dto/user";
import {
  Box,
  Button,
  Center,
  LoadingOverlay,
  Modal,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useApi } from "../useApi";
import { confirmTaskFinishedMember } from "../apiRoutes";
import { TimeInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { isTaskTypeFixed } from "../utils";
import { useState } from "react";

function cellColor(task: TaskDto) {
  switch (task.type) {
    case TaskType.recordingWriting:
      return { backgroundColor: "#C8E7F7", borderLeft: "2px solid #53B5D8" };
    case TaskType.meeting:
      return { backgroundColor: "#C2EEBA", borderLeft: "2px solid #60B286" };
    case TaskType.meetingWriting:
      return { backgroundColor: "#fffeeb", borderLeft: "2px solid #fff70e" };
    case TaskType.otherFlexible:
    case TaskType.otherFixed:
      return { backgroundColor: "#DBD6FA", borderLeft: "2px solid #8276D1" };
    default: // Absence
      return { backgroundColor: "#f4f4f4", borderLeft: "2px solid #CACACA" };
  }
}

function splitTasks(tasks: TaskDto[]): TaskDto[] {
  const fixedTasks: TaskDto[] = [];
  const flexTasks: TaskDto[] = [];

  // Separate fixed and flexible tasks
  for (const task of tasks) {
    if (isTaskTypeFixed(task.type)) {
      fixedTasks.push(task);
    } else {
      flexTasks.push(task);
    }
  }

  const processedTasks: TaskDto[] = [];

  // Add all fixed tasks to the processed tasks
  processedTasks.push(...fixedTasks.map((task) => ({ ...task })));

  // Process flexible tasks
  for (const flexTask of flexTasks) {
    let flexStart = new Date(flexTask.startDate as any).getTime();
    let flexEnd = new Date(flexTask.endDate as any).getTime();
    const newFlexTasks: TaskDto[] = [];

    // Sort fixed tasks by start time to handle overlaps correctly
    const sortedFixedTasks = fixedTasks.sort(
      (a, b) =>
        new Date(a.startDate as any).getTime() -
        new Date(b.startDate as any).getTime()
    );

    for (const fixedTask of sortedFixedTasks) {
      const fixedStart = new Date(fixedTask.startDate as any).getTime();
      const fixedEnd = new Date(fixedTask.endDate as any).getTime();

      if (flexStart < fixedStart && flexEnd > fixedStart) {
        newFlexTasks.push({
          ...flexTask,
          startDate: new Date(flexStart).toISOString() as any,
          endDate: new Date(fixedStart).toISOString() as any,
        });
        flexStart = fixedEnd;
      } else if (flexStart < fixedEnd && flexEnd > fixedEnd) {
        newFlexTasks.push({
          ...flexTask,
          startDate: new Date(fixedEnd).toISOString() as any,
          endDate: new Date(flexEnd).toISOString() as any,
        });
        flexEnd = fixedEnd;
      } else if (flexStart >= fixedStart && flexEnd <= fixedEnd) {
        flexStart = flexEnd;
      }
    }

    // Add remaining part of the flexible task if it does not overlap with any fixed task
    if (flexStart < flexEnd) {
      newFlexTasks.push({
        ...flexTask,
        startDate: new Date(flexStart).toISOString() as any,
        endDate: new Date(flexEnd).toISOString() as any,
      });
    }

    processedTasks.push(...newFlexTasks);
  }

  return processedTasks;
}

const DayCell = ({
  day,
  tasks,
  isMembers,
  setRefresh,
}: {
  day: Moment;
  tasks: TaskDto[];
  isMembers?: boolean;
  setRefresh?: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [openedTaskId, setOpenedTaskId] = useState<number | undefined>(
    undefined
  );
  const api = useApi();
  const cellHeight = isMembers ? 560 : 200;
  const totalHours = 7;
  const margin = 1;
  const [loading, setLoading] = useState(false);
  const form = useForm({
    initialValues: { timeAtTheCustomer: "", duration: "", commentRedactor: "" },
    validate: {
      timeAtTheCustomer: (value) =>
        value.length <= 0 ? "Ce champ est obligatoire" : null,
      duration: (value) =>
        value.length <= 0 ? "Ce champ est obligatoire" : null,
    },
  });

  const extractTime = (dateStr: string) => {
    const date = new Date(dateStr);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    return hours + minutes / 60;
  };

  const calculateTaskStyle = (
    start: string,
    end: string,
    isStartDay: boolean,
    isEndDay: boolean
  ) => {
    const startHour = isStartDay ? extractTime(start) : 0;
    const endHour = isEndDay ? extractTime(end) : totalHours;
    const duration = endHour - startHour;

    const top = (startHour / totalHours) * cellHeight;
    const height =
      (duration / totalHours) * cellHeight - (isMembers ? 3 : 2) * margin;

    return {
      top: `${top}px`,
      height: `${height}px`,
      duration,
    };
  };

  const isCurrentOrPastDay = (taskDate: string) => {
    const taskDay = new Date(taskDate).setHours(0, 0, 0, 0);
    const currentDay = new Date().setHours(0, 0, 0, 0);
    return taskDay <= currentDay;
  };

  return (
    <div
      style={{
        width: isMembers ? 200 : 100,
        minWidth: isMembers ? 200 : 100,
        height: cellHeight,
        borderRight: isMembers ? "1px solid #EAECF0" : undefined,
        borderBottom: isMembers ? "1px solid #EAECF0" : undefined,
        position: "relative",
      }}
    >
      {tasks.length > 0
        ? splitTasks(tasks)
            .sort((a, b) => {
              // useful to display fixed tasks above
              const aFixed = isTaskTypeFixed(a.type);
              const bFixed = isTaskTypeFixed(b.type);
              if (aFixed === bFixed) return 0;
              return aFixed ? 1 : -1;
            })
            .map((task, index) => {
              if (!task.startDate || !task.endDate) return null;

              const taskStartDate = new Date(task.startDate).toDateString();
              const taskEndDate = new Date(task.endDate).toDateString();
              const currentDate = new Date(day.toDate()).toDateString();
              const isStartDay = taskStartDate === currentDate;
              const isEndDay = taskEndDate === currentDate;
              const taskStyle = calculateTaskStyle(
                task.startDate as any as string,
                task.endDate as any as string,
                isStartDay,
                isEndDay
              );

              if (taskStyle.duration <= 0) return null;
              if (taskStyle.height === "-2px") return null;

              const title = `${task.customer?.code ?? ""} ${
                task?.customer?.processingLevel ?? ""
              } ${task?.meetingDate} ${task?.duration ?? ""} ${
                task?.commentRedaction ?? ""
              } ${task.commentRedactor ?? ""} ${
                task.timeAtTheCustomer
                  ? `(temps chez le client: ${task.timeAtTheCustomer})`
                  : ""
              }`;

              return (
                <div
                  key={index}
                  style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: taskStyle.top,
                    height: taskStyle.height,
                    boxSizing: "border-box",
                    borderRadius: 2,
                    margin,
                    overflow: "hidden",
                    ...cellColor(task),
                  }}
                  onClick={() => {
                    const deleteEvent = new CustomEvent("planTask", {
                      detail: { taskId: task.id },
                    });
                    window.dispatchEvent(deleteEvent);
                  }}
                >
                  <Tooltip label={title} withinPortal>
                    <span>
                      {/* <b>{task.id}</b>{" "} */}
                      {task.state === TaskState.documentsReceived && "📄"}
                      {task.state === TaskState.readyToStart && "▶️"}
                      {task.state === TaskState.validatedByRedactor && "✔️"}
                      {task.state === TaskState.delivered && "✉️"}
                      {task.state === TaskState.billed && "✅"}
                      {task.state ? " " : ""}
                      {task.customer?.code} {task?.customer?.processingLevel}{" "}
                      {task?.meetingDate} {task?.duration}{" "}
                      {task.commentRedaction} {task.commentRedactor}{" "}
                      {task.timeAtTheCustomer
                        ? `(temps chez le client: ${task.timeAtTheCustomer})`
                        : ""}
                    </span>
                  </Tooltip>

                  {isMembers &&
                    isCurrentOrPastDay(task.startDate as any as string) &&
                    task.state !== TaskState.validatedByRedactor &&
                    task.state !== TaskState.delivered &&
                    task.state !== TaskState.billed &&
                    task.type !== TaskType.break && (
                      <Button
                        style={{
                          height: 36,
                          position: "absolute",
                          bottom: taskStyle.duration === 0.5 ? 0 : 10, // Adjust bottom margin if the slot is 30 minutes
                          left: "50%",
                          transform: "translateX(-50%)",
                        }}
                        onClick={() => {
                          form.reset();
                          open();
                          setOpenedTaskId(task.id);
                        }}
                      >
                        {task.type === TaskType.meeting
                          ? "Ajouter la durée"
                          : "Valider"}
                      </Button>
                    )}
                  {isMembers && task.id === openedTaskId && (
                    <Modal
                      opened={opened}
                      onClose={close}
                      title={
                        task.type === TaskType.meeting
                          ? "Ajouter la durée"
                          : "Valider"
                      }
                    >
                      <LoadingOverlay visible={loading} overlayBlur={2} />
                      {task.type === TaskType.meeting && (
                        <Box>
                          <TimeInput
                            label="Temps de présence chez le client"
                            mb={10}
                            withAsterisk
                            {...form.getInputProps("timeAtTheCustomer")}
                          />
                          <TimeInput
                            label="Durée finale de la réunion"
                            mb={16}
                            withAsterisk
                            {...form.getInputProps("duration")}
                          />
                          <TextInput
                            label="Commentaire"
                            placeholder="Ajouter un commentaire"
                            {...form.getInputProps("commentRedactor")}
                            mb={16}
                          />
                        </Box>
                      )}
                      <Center>
                        <Button
                          onClick={async () => {
                            form.validate();
                            if (
                              !form.isValid() &&
                              task.type === TaskType.meeting
                            )
                              return;
                            setLoading(true);
                            try {
                              await confirmTaskFinishedMember(
                                api,
                                task.id ?? 0,
                                form.values.timeAtTheCustomer,
                                form.values.duration,
                                form.values.commentRedactor
                              );
                              setRefresh?.((prev) => prev + 1);
                              close();
                            } catch (error) {
                              console.error("Error confirming task:", error);
                            } finally {
                              setLoading(false);
                            }
                          }}
                        >
                          Confirmer
                        </Button>
                      </Center>
                    </Modal>
                  )}
                </div>
              );
            })
        : null}
    </div>
  );
};

export default DayCell;
